@keyframes gradientMove {
    0% {
      background-position: 100% 0%;
    }
    50% {
      background-position: 50% 0%;
    }
    100% {
      background-position: 0% 0%;
    }
  }
  
  .animatedGradient {
    background-size: 150% 100%;
    animation: gradientMove 1s linear;
  }
  