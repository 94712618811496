.rotating-dash-circle {
  animation: rotateDash 60s linear infinite;
}

@keyframes rotateDash {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: -200;
  }
}

.hover-rectangle-explore:hover {
  cursor: pointer;
}

.text-content-explore {
  transform: translateY(5px);
  opacity: 0;
  transition: transform 0.5s ease, opacity 0.5s ease;
  padding: 5px;
  fill: white;
  pointer-events: none;
}

.hover-rectangle-explore:hover~.animated-content,
.hover-rectangle-explore:hover~.text-content-explore {
  opacity: 1;
  transform: translateY(0);
}

.hover-rectangle-explore:hover .bottom-top-top-explore {
  transform: translateY(-10px);
  transition: transform 0.3s ease-out;
}

.hover-rectangle-explore-left:hover {
  cursor: pointer;
}

.text-content-explore-left {
  transform: translateY(5px);
  opacity: 0;
  transition: transform 0.5s ease, opacity .5s ease;
  padding: 5px;
  fill: white;
  pointer-events: none;
}

.hover-rectangle-explore-left:hover~.animated-content-left,
.hover-rectangle-explore-left:hover~.text-content-explore-left {
  opacity: 1;
  transform: translateY(0);
}

.hover-rectangle-explore-left:hover .bottom-top-top-explore-left {
  transform: translateY(-10px);
  transition: transform 0.3s ease-out;
}

.bottom-top-top-explore-left rect {
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px) translateX(-13px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.bottom-top-top-explore-left path {
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px) translateX(-5px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hover-rectangle-explore-left:hover+.bottom-top-top-explore-left rect {
  opacity: 1;
  transform: translateY(8px) translateX(-10px);
}

.hover-rectangle-explore-left:hover+.bottom-top-top-explore-left path {
  opacity: 1;
  transform: translateY(1px) translateX(8px);
}

.bottom-top-top-explore rect {
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px) translateX(-13px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.bottom-top-top-explore path {
  pointer-events: none;
  opacity: 0;
  transform: translateY(20px) translateX(-5px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.hover-rectangle-explore:hover+.bottom-top-top-explore rect {
  opacity: 1;
  transform: translateY(4px) translateX(-10px);
}

.hover-rectangle-explore:hover+.bottom-top-top-explore path {
  opacity: 1;
  transform: translateY(-3px) translateX(8px);
}

.line-animation-vertical {
  stroke-dasharray: 100;
  stroke-dashoffset: -100;
  animation: draw-line 1s linear 1.5s forwards;
}

@keyframes draw-line {
  from {
    stroke-dashoffset: -100;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.line-animation-horizontal {
  stroke-dasharray: 210;
  stroke-dashoffset: -210;
  animation: draw-line-right 1s linear 1.5s forwards;
}

@keyframes draw-line-right {
  from {
    stroke-dashoffset: -210;
  }

  to {
    stroke-dashoffset: 0;
  }
}

.path-animation {
  transform-origin: 53.33% 14.285%;
  transform: scale(0);
  animation: reveal-scale 1s ease-in-out 2s forwards;
}

@keyframes reveal-scale {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.rect-animation {
  clip-path: inset(0 0 100% 0);
  animation: reveal-top-to-bottom 1s ease-in-out 1.8s forwards;
}

@keyframes reveal-top-to-bottom {
  from {
    clip-path: inset(0 0 100% 0);
  }

  to {
    clip-path: inset(0 0 0 0);
  }
}

.reveal-opacity {
  opacity: 0;
  animation: fadeIn 1s ease-in-out 2s forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.text-animation {
  opacity: 0;
  transform: translateY(-5px);
  animation: reveal-text 1s ease-in-out 2s forwards;
}

.reveal-text-animation {
  opacity: 0;
  transform: translateY(-5px);
  animation: reveal-text-animation 1s ease-in-out 2s forwards;
}

@keyframes reveal-text-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes reveal-text {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.rotating-dash-circle {
  opacity: 0;
  transform: scale(0.8);
  animation: circle-reveal 1s ease-in-out 2s forwards, rotateDash 60s linear infinite 2.6s;
}

@keyframes circle-reveal {
  from {
    opacity: 0;
    transform: translateY(-3px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.path-animation-rotate {
  opacity: 0;
  animation: path-reveal-animation 1s ease-in-out 2s forwards;
}

@keyframes path-reveal-animation {
  from {
    opacity: 0;
    transform: scale(0.1) translate(1080px, 300px);
  }

  to {
    opacity: 1;
    transform: scale(0.1) translate(1080px, 330px);
  }
}
